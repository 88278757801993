import {Button, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import {IError} from "../../api/models/errorModel";
import SelectImage from "../../components/SelectImage";
import RemoveImageBackground from "../../components/RemoveImageBackground";

import {t} from "i18next";

interface Props {
    storeName: string;
    logoUrl?: string;
    logoBase64?: string;
    error?: IError;
    onPropertyChanged: (propertyName: string, value: any) => void;
}

const StoreSettingsNameLogo = ({
                                   storeName,
                                   logoUrl,
                                   logoBase64,
                                   error,
                                   onPropertyChanged,
                               }: Props) => {
    return (
        <div>
            <div id="name" className="panel">
                <FormGroup>
                    <FormLabel>{t("name")}</FormLabel>
                    <FormControl
                        type="text"
                        placeholder={t(
                            "storeSettingsNameLogo_store_name_placeholder"
                        )}
                        value={storeName}
                        isInvalid={error?.property === "storeName"}
                        onChange={(e) =>
                            onPropertyChanged(
                                "storeName",
                                e.currentTarget.value
                            )
                        }
                    />
                </FormGroup>

                <FormGroup className="mt-2">
                    <FormLabel>
                        {t("storeSettingsNameLogo_store_logo")}
                    </FormLabel>

                    <SelectImage
                        imageUrl={logoUrl ? logoUrl : ""}
                        imageWidth="50px"
                        imageHeight="50px"
                        imageAlt="Logo"
                        buttonVariant="primary"
                        onImageChanged={(images) =>
                            onPropertyChanged("logoBase64", images[0])
                        }
                    />
                    {logoBase64 && (
                        <button
                            className="btn btn-lg btn-outline-danger w-100 mt-2"
                            onClick={() => onPropertyChanged("logoBase64", undefined)}>
                            {t("storeSettingsCarousel_delete_image")}
                        </button>)}
                   
                </FormGroup>
            </div>
        </div>
    );
};

export default StoreSettingsNameLogo;
