import {useEffect, useState} from "react";
import {User, UserDoorAssignation} from "../../api/models/userModel";
import {eActionType} from "../../api/models/common";
import {useNavigate, useParams} from "react-router-dom";
import TitleBar from "../../components/TitleBar";
import {Alert, Button, Form} from "react-bootstrap";
import {IError} from "../../api/models/errorModel";
import LoadingComponent from "../../components/LoadingComponent";
import {
    CreateUser,
    GetUser,
    GetUserDoorAssignation,
    UpdateUser,
} from "../../api/services/UsersServices";
import Tabs from "../../components/Tab";
import {t} from "i18next";

const UpdateUserPage = () => {
    const [user, setUser] = useState<User>({
        name: "",
        eMail: "",
        password: "",
        doors: [],
        disabled: false,
    });
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const [doors, setDoors] = useState<UserDoorAssignation[]>([]);

    const [UserId, setUserId] = useState<string>("");
    const [changePassword, setChangePassword] = useState<boolean>(false);

    const [actionType, setActionType] = useState<eActionType>();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const [error, setError] = useState<IError>();

    const [loadingError, setLoadingError] = useState<React.ReactNode>();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState<string | undefined>();

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
        if (params.userId) {
            getUser(params.userId);
        } else {
            setActionType(eActionType.Create);
            getDoors("{00000000-0000-0000-0000-000000000000}");
        }
    }, []);

    const getUser = async (userId: string) => {
        try {
            if (!userId) return;

            setIsLoading(true);
            setLoadingText(t("loading"));
            setLoadingError(undefined);
            setError(undefined);

            const u = await GetUser(userId);
            setUser(u);
            setUserId(userId);

            await getDoors(userId);

            console.log("user", u);

            setActionType(eActionType.Update);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")}
                    <br/> {t("please_try_again")}.
                </p>
            );
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const getDoors = async (userId: string) => {
        try {
            const d = await GetUserDoorAssignation(userId);
            d.sort((a, b) => (a.doorName > b.doorName ? 1 : -1));
            setDoors(d);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")}
                    <br/> {t("please_try_again")}.
                </p>
            );
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const save = async () => {
        if (!validate()) return;

        try {
            setIsLoading(true);
            setLoadingText(`${t("saving")}...`);
            setError(undefined);

            if (actionType === eActionType.Create)
                await CreateUser(user, doors);
            if (actionType === eActionType.Update)
                await UpdateUser(user, UserId, doors, user.disabled);

            navigate(-1);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({message: message as string});
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
    };

    const validate = () => {
        if (!user.name) {
            setError({
                message: t("updateUserPage_name_required"),
                property: "name",
            });
            return false;
        }

        if (!user.eMail) {
            setError({
                message: t("updateUserPage_email_required"),
                property: "eMail",
            });
            return false;
        }
        if (actionType === eActionType.Create || changePassword) {
            if (!user.password) {
                setError({
                    message: t("updateUserPage_password_required"),
                    property: "password",
                });
                return false;
            }

            if (user.password.length < 6) {
                setError({
                    message: t("updateUserPage_password_min_length"),
                    property: "password",
                });
                return false;
            }

            if (!confirmPassword) {
                setError({
                    message: t("updateUserPage_confirm_password_error"),
                    property: "confirmPassword",
                });
                return false;
            }

            if (user.password !== confirmPassword) {
                setError({
                    message: t("updateUserPage_confirm_password_not_match"),
                    property: "confirmPassword",
                });
                return false;
            }
        }
        setError(undefined);
        return true;
    };

    const enableDisableUser = async () => {
        try {
            setIsLoading(true);
            setLoadingText(`${t("saving")}...`);
            setError(undefined);
            await UpdateUser(user, UserId, doors, !user.disabled);
            navigate(-1);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({message: message as string});
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
    }

    const selectAll=()=> {
        doors.forEach((door) => {
            door.doorAssigned = true;
        });
        setDoors([...doors]);
    }

    const unSelectAll=()=> {
        doors.forEach((door) => {
            door.doorAssigned = false;
        });
        setDoors([...doors]);
    };
    
    return (
        <div id="updateUser">
            <TitleBar
                title={
                    actionType === eActionType.Create
                        ? t("updateUserPage_create_user")
                        : t("updateUserPage_edit_user")
                }
                backUrl="-1"
            />

            <Tabs
                tabs={[
                    t("updateUserPage_tabs_general"),
                    t("updateUserPage_tabs_roles"),
                ]}
                selectedTabIndex={selectedTab}
                onSelectedTabChanged={(s) => setSelectedTab(s)}
            />
            <Form className="panel">
                {selectedTab === 0 && (
                    <div id="generales">
                        <Form.Group id="name" className="formGroup">
                            <Form.Label className="required">
                                {t("name")}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={user.name}
                                isInvalid={error?.property === "name"}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group id="eMail" className="formGroup">
                            <Form.Label className="required">eMail</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={user.eMail}
                                isInvalid={error?.property === "eMail"}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        eMail: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>

                        {actionType === eActionType.Update &&
                            !changePassword && (
                                <div>
                                    <Button
                                        className="mt-2 w-100"
                                        variant="outline-primary"
                                        onClick={() => setChangePassword(true)}
                                    >
                                        {t("updateUserPage_change_password")}
                                    </Button>
                                    <Button className="mt-2 w-100"
                                            variant="outline-secondary"
                                            onClick={async () => await enableDisableUser()}>
                                        {user.disabled ? t("updateUserPage_enable_user") : t("updateUserPage_disable_user")}
                                    </Button>
                                </div>
                            )}
                        {(actionType === eActionType.Create ||
                            changePassword) && (
                            <div>
                                <Form.Group id="Password" className="formGroup">
                                    <Form.Label className="required">
                                        {t("updateUserPage_password")}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder=""
                                        value={user.password}
                                        isInvalid={
                                            error?.property === "password"
                                        }
                                        onChange={(e) =>
                                            setUser({
                                                ...user,
                                                password: e.currentTarget.value,
                                            })
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                    id="ConfirmPassword"
                                    className="formGroup"
                                >
                                    <Form.Label className="required">
                                        {t("updateUserPage_confirm_password")}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder=""
                                        value={confirmPassword}
                                        isInvalid={
                                            error?.property ===
                                            "confirmPassword"
                                        }
                                        onChange={(e) =>
                                            setConfirmPassword(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </div>
                )}

                {selectedTab === 1 && (
                    <div id="permisos">
                        <div>
                            <div className="my-2">
                                <Button size="sm" onClick={selectAll}>
                                    {t("updateUserPage_select_all")}
                                </Button>
                                <Button className="ms-1"size="sm" onClick={unSelectAll}> 
                                    {t("updateUserPage_unselect_all")}
                                </Button>
                            </div>
                            {doors.map((door, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={door.doorName}
                                    checked={door.doorAssigned}
                                    className="my-2 py-2"
                                    style={{
                                        transform: "scale(1.3)",       // Scale up the checkbox
                                        transformOrigin: "left center" // Keep it aligned
                                    }}
                                    onChange={(e) => {
                                        door.doorAssigned = e.currentTarget
                                            .checked
                                            ? true
                                            : false;
                                        setDoors([...doors]);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </Form>

            {error && (
                <Alert className="mt-4 mb-2" variant="danger">
                    {error.message}
                </Alert>
            )}

            <div className="bottom-toolbar">
                <Button size="lg" variant="success" onClick={save}>
                    {t("save")}
                </Button>
            </div>

            {isLoading && <LoadingComponent loadingText={loadingText}/>}
        </div>
    );
};

export default UpdateUserPage;
