import { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    FechaEntregaDefault,
    GetFechaDesde,
    GetFechaHasta,
    RangoFechas,
} from "../../../api/helpers/datesHelpers";
import { FormatDecimal, FormatDate } from "../../../api/helpers/formatters";
import {
    GetOrders,
    GetOrdersViewModel,
} from "../../../api/services/ordersServices";
import DateRangeLink from "../../../components/DateRangeLink";
import LabelGroup from "../../../components/LabelGroup";
import SearchEntry from "../../../components/SearchEntry";
import Tabs from "../../../components/Tab";
import TitleBar from "../../../components/TitleBar";
import DeliveryTypeBadge from "../Components/DeliveryTypeBadge";
import { eOrderStatus } from "../../../api/models/orderModel";
import { Badge } from "react-bootstrap";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useSession } from "../../../api/context/SessionContext";
import { t } from "i18next";
import {fi} from "date-fns/locale";

const OrdersListPage = () => {
    const navigate = useNavigate();
    const session = useSession();
    const { search } = useLocation();

    const [searchText, setSearchText] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const [orders, setOrders] = useState<GetOrdersViewModel[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    const [filterDeliveryType, setFilterDeliveryType] = useState<number>(0);

    const [rangoFechas, setRangoFechas] = useState<RangoFechas>({
        Desde: GetFechaDesde(new Date()),
        Hasta: GetFechaHasta(FechaEntregaDefault()),
    });
    
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("69128f6d-badc-4926-9342-97f2bc3c7af1")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
            return;
        }
        
    
        fetchData();
    }, [rangoFechas, selectedStatus]);

    const fetchData = async () => {
        
        let filtroFechas = rangoFechas;
        let filtroEstatus = selectedStatus;
        
        if(firstLoad) {
            const params = new URLSearchParams(search);
            if (params.has("desde") && params.has("hasta") && params.has("selectedStatus")) {
                
                filtroFechas = {
                    Desde: new Date(params.get("desde") || ""),
                    Hasta: new Date(params.get("hasta") || ""),
                };
                setRangoFechas(filtroFechas);
                
                filtroEstatus = parseInt(params.get("selectedStatus") || "0");
                setSelectedStatus(filtroEstatus);
            }
            setFirstLoad(false);
        }
        
        try {
            setOrders([]);
            setIsLoading(true);
            setErrorMessage(undefined);
            let status = filtroEstatus === 6 ? 10 : filtroEstatus;
            const response = await GetOrders(status, filtroFechas);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };
    
    const getNavigationParameters = () =>  new URLSearchParams({
        desde: rangoFechas.Desde.toISOString(),
        hasta: rangoFechas.Hasta.toISOString(),
        selectedStatus: selectedStatus.toString(),
    }).toString();

    const filterOrders = () => {
        let ds = orders;

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter(
                (x) =>
                    x.id.toString().includes(searchTextLower) ||
                    x.customerName.toLocaleLowerCase().includes(searchTextLower)
            );
        }

        if (filterDeliveryType > 0)
            ds = ds.filter((x) => x.deliveryType === filterDeliveryType - 1);

        return ds;
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <PullToRefresh onRefresh={async () => await fetchData()}>
            <div>
                <TitleBar title={t("ordersListPage_title")}>
                    <div className="d-flex">
                        <LabelGroup caption={t("promised_delivery_date")}>
                            <DateRangeLink
                                rangoFechas={rangoFechas}
                                onChange={async (nuevoRango: RangoFechas) => {
                                    setRangoFechas(nuevoRango);
                                }}
                            />
                        </LabelGroup>
                    </div>
                </TitleBar>

                <SearchEntry onChange={(e) => setSearchText(e)}/>

                <Tabs
                    tabs={[
                        t("por Surtir"),
                        t("Surtiendo"),
                        t("por Empacar"),
                        t("Empacados"),
                        t("Enviados"),
                        t("Entregados"),
                        t("Cancelados"),
                    ]}
                    selectedTabIndex={selectedStatus}
                    onSelectedTabChanged={async (e) => {
                        setSelectedStatus(e);
                    }}
                />

                <Tabs
                    tabs={[
                        t("deliverOrders_filter_all"),
                        t("deliverOrders_filter_pickup"),
                        t("deliverOrders_filter_delivery"),
                    ]}
                    selectedTabIndex={filterDeliveryType}
                    onSelectedTabChanged={(e) => setFilterDeliveryType(e)}
                />

                {filterOrders().length > 5 && (
                    <div
                        id="totals"
                        className="d-flex justify-content-between mt-4 mb-4"
                    >
                        <LabelGroup caption={t("orderListPage_count_orders")}>
                            {filterOrders().length}
                        </LabelGroup>
                        <LabelGroup caption="Total">
                            {FormatDecimal(
                                filterOrders().reduce(
                                    (sum, item) => sum + item.total,
                                    0
                                ),
                                true
                            )}
                        </LabelGroup>
                    </div>
                    )}
               

                <div id="list">
                    {filterOrders().map((x, i) => (
                        <div
                            key={i}
                            className="panel"
                            onClick={() => navigate(`../orders/${x.id}?${getNavigationParameters()}`)}
                        >
                            <div className="d-flex justify-content-between">
                                <LabelGroup caption={t("order")}>
                                    {x.orderNumber.toString()}
                                </LabelGroup>
                                <LabelGroup
                                    caption={t("promised_delivery_date")}
                                >
                                    {FormatDate(x.promisedDeliveryDate, true)}
                                </LabelGroup>
                            </div>
                            <div>{x.customerName}</div>
                            <div className="mt-1 d-flex justify-content-between align-items-center">
                                <DeliveryTypeBadge
                                    deliveryType={x.deliveryType}
                                />
                                <LabelGroup caption="Total">
                                    {FormatDecimal(x.total, true)}
                                </LabelGroup>
                                {selectedStatus >= eOrderStatus.Picked && (
                                    <LabelGroup caption={t("Saldo")}>
                                        <Badge
                                            pill
                                            bg={
                                                x.balance <= 0
                                                    ? "success"
                                                    : "secondary"
                                            }
                                        >
                                            {FormatDecimal(x.balance, true)}
                                        </Badge>
                                    </LabelGroup>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    id="totals"
                    className="d-flex justify-content-between mt-4 mb-4"
                >
                    <LabelGroup caption={t("orderListPage_count_orders")}>
                        {filterOrders().length}
                    </LabelGroup>
                    <LabelGroup caption="Total">
                        {FormatDecimal(
                            filterOrders().reduce(
                                (sum, item) => sum + item.total,
                                0
                            ),
                            true
                        )}
                    </LabelGroup>
                </div>
            </div>
        </PullToRefresh>
    );
};

export default OrdersListPage;
