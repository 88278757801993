export enum eProductTag {
  None = 0,
  New,
  Sale,
  Limited,
}
export type Product = {
  id: number;
  name: string;
  searchTermsArray: string[];
  searchTerms: string;
  tag: eProductTag;
  order: number;
  thumbUrl: string;
  thumbBase64?: string;
  removeImage: boolean;
  originalImageUrl?: string;
  regularPrice?: number;
  price: number;
  cost: number;
  margin?: number;
  addTransactionCharge: boolean;
  unitOfMeasure: string;
  interval: number;
  productCategoryId: number;
  productSubCategoryId?: number;
  featured: boolean;
  disabled: boolean;
  outOfStock: boolean;
  locationCode: string;
  supplierName?: string;
  lastPurchasePrice?: number;
};
