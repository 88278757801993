import { useEffect, useState } from "react";
import {
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from "react-bootstrap";
import { eDeliveryType, Order } from "../../../api/models/orderModel";
import {
    CalculateOrder,
    IsOrderEditable,
} from "../../../api/services/ordersServices";
import { UserHasPermission } from "../../../api/services/UsersServices";
import NumericInput from "../../../components/NumericInput";
import { t } from "i18next";

interface Props {
    order: Order;
    onComplete: (order: Order) => void;
    onHide: () => void;
}

const EditOrder = (props: Props) => {
    const [order, setOrder] = useState<Order>(props.order);

    const allowEdit = IsOrderEditable(props.order.status);
    const [allowEditDiscount, setAllowEditDiscount] = useState(false);
    const [allowEditDeliveryDate, setAllowEditDeliveryDate] = useState(false);

    useEffect(() => {
        if (!order.shippingAddress)
            setOrder({
                ...order,
                shippingAddress: {
                    street: "",
                    outsideNumber: "",
                    innerNumber: "",
                    neighborhood: "",
                    city: "",
                    state: "",
                    country: "",
                    zipCode: "",
                    lat: 0,
                    lng: 0,
                },
            });

        setAllowEditDiscount(UserHasPermission("Orders/EditDiscount"));
        setAllowEditDeliveryDate(UserHasPermission("Orders/EditDeliveryDate"));
    }, [order]);

    return (
        <Modal show={true} onHide={props.onHide}>
            <ModalHeader closeButton>
                <ModalTitle>
                    {allowEdit ? t("edit") : t("view")}
                    {t("order")}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Form.Group id="name">
                        <Form.Label className="required">
                            {t("name")}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            disabled={!allowEdit}
                            value={order.customer.name}
                            onChange={(e) =>
                                setOrder({
                                    ...order,
                                    customer: {
                                        ...order.customer,
                                        name: e.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group id="eMail">
                        <Form.Label className="required">eMail</Form.Label>
                        <Form.Control
                            type="email"
                            disabled={!allowEdit}
                            value={order.customer.email}
                            onChange={(e) =>
                                setOrder({
                                    ...order,
                                    customer: {
                                        ...order.customer,
                                        email: e.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group id="phone">
                        <Form.Label className="required">
                            {t("phone")}
                        </Form.Label>
                        <Form.Control
                            type="tel"
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            disabled={!allowEdit}
                            value={order.customer.phone}
                            onChange={(e) =>
                                setOrder({
                                    ...order,
                                    customer: {
                                        ...order.customer,
                                        phone: e.currentTarget.value,
                                    },
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group id="deliveryType">
                        <Form.Label>{t("delivery_type")}</Form.Label>
                        <Form.Check
                            type={"radio"}
                            disabled={!allowEdit}
                            checked={
                                order.deliveryType === eDeliveryType.Delivery
                            }
                            onChange={(e) =>
                                setOrder({
                                    ...order,
                                    deliveryType: eDeliveryType.Delivery,
                                })
                            }
                            id={eDeliveryType.Delivery.toString()}
                            label={t("deliveryBadge_delivery")}
                        />
                        <Form.Check
                            type={"radio"}
                            disabled={!allowEdit}
                            checked={
                                order.deliveryType === eDeliveryType.Pickup
                            }
                            onChange={(e) =>
                                setOrder({
                                    ...order,
                                    deliveryType: eDeliveryType.Pickup,
                                })
                            }
                            id={eDeliveryType.Pickup.toString()}
                            label={t("pickup")}
                        />
                    </Form.Group>
                    {order.deliveryType === eDeliveryType.Delivery && (
                        <div>
                            <Form.Group id="street">
                                <Form.Label className="required">
                                    {t("street")}
                                </Form.Label>
                                <Form.Control
                                    disabled={!allowEdit}
                                    value={order.shippingAddress?.street}
                                    onChange={(e) =>
                                        setOrder({
                                            ...order,
                                            shippingAddress: {
                                                ...order.shippingAddress!,
                                                street: e.currentTarget.value,
                                            },
                                        })
                                    }
                                />
                            </Form.Group>

                            <div
                                id="outside_inside_number"
                                className="d-flex direction-column"
                            >
                                <Form.Group>
                                    <Form.Label className="required">
                                        {t("outside_number")}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!allowEdit}
                                        value={
                                            order.shippingAddress?.outsideNumber
                                        }
                                        onChange={(e) =>
                                            setOrder({
                                                ...order,
                                                shippingAddress: {
                                                    ...order.shippingAddress!,
                                                    outsideNumber:
                                                        e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ms-2">
                                    <Form.Label>{t("inner_number")}</Form.Label>
                                    <Form.Control
                                        disabled={!allowEdit}
                                        value={
                                            order.shippingAddress?.innerNumber
                                        }
                                        onChange={(e) =>
                                            setOrder({
                                                ...order,
                                                shippingAddress: {
                                                    ...order.shippingAddress!,
                                                    innerNumber:
                                                        e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </Form.Group>
                            </div>

                            <Form.Group id="zipCode">
                                <Form.Label className="required">
                                    {t("zip_code")}
                                </Form.Label>
                                <Form.Control
                                    disabled={!allowEdit}
                                    value={order.shippingAddress?.zipCode}
                                    onChange={(e) =>
                                        setOrder({
                                            ...order,
                                            shippingAddress: {
                                                ...order.shippingAddress!,
                                                zipCode: e.currentTarget.value,
                                            },
                                        })
                                    }
                                />
                            </Form.Group>

                            <Form.Group id="neighborhood">
                                <Form.Label>{t("neighborhood")}</Form.Label>
                                <Form.Control
                                    disabled={!allowEdit}
                                    value={order.shippingAddress?.neighborhood}
                                    onChange={(e) =>
                                        setOrder({
                                            ...order,
                                            shippingAddress: {
                                                ...order.shippingAddress!,
                                                neighborhood:
                                                    e.currentTarget.value,
                                            },
                                        })
                                    }
                                />
                            </Form.Group>

                            <div
                                id="city_state"
                                className="d-flex direction-column"
                            >
                                <Form.Group>
                                    <Form.Label className="required">
                                        {t("city")}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!allowEdit}
                                        value={order.shippingAddress?.city}
                                        onChange={(e) =>
                                            setOrder({
                                                ...order,
                                                shippingAddress: {
                                                    ...order.shippingAddress!,
                                                    city: e.currentTarget.value,
                                                },
                                            })
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ms-2">
                                    <Form.Label className="required">
                                        {t("state")}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!allowEdit}
                                        value={order.shippingAddress?.state}
                                        onChange={(e) =>
                                            setOrder({
                                                ...order,
                                                shippingAddress: {
                                                    ...order.shippingAddress!,
                                                    state: e.currentTarget
                                                        .value,
                                                },
                                            })
                                        }
                                    />
                                </Form.Group>
                            </div>

                            <Form.Group id="shippingFee">
                                <Form.Label>{t("delivery_fee")}</Form.Label>
                                <NumericInput
                                    disabled={!allowEdit}
                                    value={order.deliveryFee}
                                    onValueChanged={(e) =>
                                        setOrder({
                                            ...order,
                                            deliveryFee: e,
                                        })
                                    }
                                />
                            </Form.Group>
                        </div>
                    )}


                    <Form.Group id="notes">
                        <Form.Label>{t("notes")}</Form.Label>
                        <Form.Control
                            as={"textarea"}
                            rows={3}
                            disabled={!allowEdit}
                            value={order.notes}
                            onChange={(e) =>
                                setOrder({
                                    ...order,
                                    notes: e.currentTarget.value,
                                })
                            }
                        />
                    </Form.Group>


                    {allowEditDeliveryDate && (
                        <Form.Group id="deliveryDate">
                            <Form.Label className="required">
                                {t("promised_delivery_date")}:
                            </Form.Label>
                            <Form.Control
                                type="date"
                                disabled={!allowEdit}
                                value={order.promisedDeliveryDate}
                                onChange={(e) =>
                                    setOrder({
                                        ...order,
                                        promisedDeliveryDate:
                                            e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                    )}
                </Form>
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-success"
                    disabled={!allowEdit}
                    onClick={() => {
                        CalculateOrder(order);
                        setOrder({ ...order });
                        props.onComplete(order);
                    }}
                >
                    {t("save")}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default EditOrder;
