import {BuildUrl, CreateAxiosInstance} from "../helpers/apiHelpers";
import {User, UserDoorAssignation} from "../models/userModel";

const axiosInstance = CreateAxiosInstance();

export const UserHasPermission = (permission: string): boolean => {
    //TODO: Request to API
    return true;
};

export const Login = async (email: string, password: string) => {
    try {
        const response = await axiosInstance.post(BuildUrl("users/login"), {
            email: email,
            password: password,
        });

        return response.data as LoginResponse;
    } catch (error) {
        console.log("Error login", error);
        throw error;
    }
};

export const SendResetPasswordRequest = async (email: string) => {
    try {
        console.log(BuildUrl(`users/reset-password-request?eMail=${email}`));
        const response = await axiosInstance.post(
            BuildUrl(`users/reset-password-request?eMail=${email}`), {});

        return response.data as SendResetPasswordResponse;
    } catch (error) {
        console.log("Error resetting password", error);
        throw error;
    }

}

export type SendResetPasswordResponse = {
    success: boolean;
    message: string;
};

export type LoginResponse = {
    success: boolean;
    message: string;
    token: string;
};

export const GetUsers = async (tenantId: string): Promise<[]> => {
    try {
        const response = await axiosInstance.get(BuildUrl("users/tenant"));
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al obtener los usuarios"
            );

        return response.data.users;
    } catch (error) {
        const message = "Error al obtener los usuarios " + error;
        console.error(message);
        throw new Error(message);
    }
};

export const GetUser = async (userId: string): Promise<User> => {
    try {
        const response = await axiosInstance.get(
            BuildUrl(`users/tenant/${userId}`)
        );
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al obtener el usuario"
            );

        return response.data;
    } catch (error) {
        const message = "Error al obtener el usuario " + error;
        console.error(message);
        throw new Error(message);
    }
};

export const GetUserDoorAssignation = async (
    userId: string
): Promise<UserDoorAssignation[]> => {
    try {
        const response = await axiosInstance.get(
            BuildUrl(`users/tenant/${userId}/doors`)
        );
        if (!response.data.success)
            throw new Error(
                response.data.message ||
                "Error al obtener los permisos del usuario"
            );

        return response.data.assignations;
    } catch (error) {
        const message = "Error al obtener los permisos del usuario " + error;
        console.error(message);
        throw new Error(message);
    }
};

export const CreateUser = async (user: User, doors: UserDoorAssignation[]) => {
    try {
        const request = {
            name: user.name,
            email: user.eMail,
            password: user.password,
            doors,
        };
        const response = await axiosInstance.post(
            BuildUrl("users/tenant"),
            request
        );
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al crear el usuario"
            );
    } catch (error) {
        console.log("Error al crear el usuario", error);
        throw error;
    }
};

export const UpdateUser = async (
    user: User,
    userId: string,
    doors: UserDoorAssignation[],
    disabled: boolean
) => {
    try {
        const request = {
            name: user.name,
            email: user.eMail,
            password: user.password,
            doors,
            disabled,
        };

        const response = await axiosInstance.put(
            BuildUrl(`users/tenant/${userId}`),
            request
        );
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al modificar al usuario"
            );
    } catch (error) {
        console.log("Error al modificar al usuairo", error);
        throw error;
    }
};
