import { AxiosError, AxiosResponse } from "axios";
import { Store } from "../models/storeModel";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";
import { convertImageUrlToBase64 } from "../helpers/imagesHelpers";

const axiosInstance = CreateAxiosInstance();

interface GetStoreResponse {
    success: boolean;
    message?: string;
    store: Store;
}

export const GetStore = async (): Promise<Store> => {
    try {
        const response: AxiosResponse<GetStoreResponse> =
            await axiosInstance.get(BuildUrl("store"));

        if (!response.data.success)
            throw new Error(response.data.message || "Error getting store");

        const store = response.data.store;
        
        if (!store.workingDays || store.workingDays.length === 0)
            store.workingDays = [0, 1, 1, 1, 1, 1, 0];

        return store;
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new Error("Store not found");
                }
            }
        }
        console.log("Error getting store ... ", error);
        throw new Error("Error getting store " + error);
    }
};

interface UpdateStoreResponse {
    success: boolean;
    message?: string;
}

export const UpdateStore = async (
    store: Store
): Promise<UpdateStoreResponse> => {
    try {
        //store.logoBase64 = await convertImageUrlToBase64(store.logoUrl);
        await saveCarousel(store);

        const response = await axiosInstance.put(BuildUrl("store"), store);

        return response.data as UpdateStoreResponse;
    } catch (error) {
        if (error instanceof AxiosError)
            return { success: false, message: error.message };

        return { success: false, message: "Error al guardar la tienda" };
    }
};

async function saveCarousel(store: Store) {
    if (store.carouselUrls.length === 0) return;

    store.carouselBase64 = [];

    // Iterates over the carousel images
    for (let i = 0; i < store.carouselUrls.length; i++) {
        const url = store.carouselUrls[i];

        // if it's not a new image, it's not necessary to convert it to base64
        // but adds an empty string to the carouselBase64 array to match with the urls array
        if (!url.startsWith("blob:")) {
            store.carouselBase64.push("");
            continue;
        }

        var base64image = await convertImageUrlToBase64(url);
        if (base64image) store.carouselBase64.push(base64image);
    }
}
