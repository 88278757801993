import {t} from "i18next";
import {ChangeEvent, useRef, useState, useEffect} from "react";
import {Button, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {LoadingButton} from "./LoadingButton";
import {useStoreContext} from "../api/context/StoreContext";

interface SelectImageProps {
    imageUrl: string;
    imageAlt?: string;
    imageWidth?: string;
    imageHeight?: string;
    buttonText?: string;
    buttonVariant?: string;
    multiple?: boolean;
    // Returns an array of two base64 strings: [original, preview]
    onImageChanged: (images: string[]) => void;
    // The preview size (in pixels) for both width and height
    sizes?: {
        preview: number;
    };
}

const SelectImage = ({
                         imageUrl,
                         imageAlt = "",
                         imageWidth = "100%",
                         imageHeight = "100%",
                         buttonText = t("select-image"),
                         buttonVariant = "success",
                         multiple = false,
                         onImageChanged,
                         sizes = {preview: 512},
                     }: SelectImageProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    // State for the original image (as base64) and the preview image.
    const [originalImage, setOriginalImage] = useState<string | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [isRemovingBackground, setIsRemovingBackground] = useState(false);

    const { store: storeContext } = useStoreContext();
    const store = storeContext;
    
    const [loadingOriginalImage, setLoadingOriginalImage] = useState(false);

    const tooltip = (
        <Tooltip id="img-tooltip">
            <strong>{t("select-image-specifications-0", {imageWidth, imageHeight})}</strong>
        </Tooltip>
    );

    // On initialization, if an imageUrl is provided, build the preview.
    useEffect(() => {
        
        if (imageUrl && imageUrl !== "/img/no-image.png") {
            setLoadingOriginalImage(true);
            loadImage(imageUrl)
                .then((img) => {
                    const originalBase64 = imageToBase64(img);
                    const preview = cropImageToSquare(img, sizes.preview);
                    setPreviewImage(preview);
                    setOriginalImage(originalBase64);
                    onImageChanged([originalBase64, preview]);
                })
                .catch((err) => console.error("Error loading initial image:", err))
                .finally(() => setLoadingOriginalImage(false));
        } else {
            setPreviewImage(null);
            setOriginalImage(null);
        }

    }, [imageUrl, sizes.preview]);

    // Handle file selection
    async function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        const file = files[0];

        try {
            // Convert the selected file to base64 (original image with background)
            const origBase64 = await blobToBase64(file);
            setOriginalImage(origBase64);

            // Create preview image by cropping/resizing the original image.
            const loadedImg = await loadImage(origBase64);
            const preview = cropImageToSquare(loadedImg, sizes.preview);
            setPreviewImage(preview);

            // Return both images via onImageChanged.
            onImageChanged([origBase64, preview]);

        } catch (error) {
            console.error("Error processing image:", error);
        }
    }

    // Handle background removal using originalImage (base64) always.
    async function handleRemoveBackground() {
              
        if (!originalImage) return;
        
        setIsRemovingBackground(true);
        try {

            // Convert the originalImage (base64) to a File for background removal.
            const fileForBgRemoval = base64ToFile(originalImage, "original.png");
            
            const processedBlob = await removeBackground(fileForBgRemoval, store.photoRoomApiKey!);
            const processedBase64 = await blobToBase64(processedBlob);

            // Update the original image to the background-removed version.
            setOriginalImage(processedBase64);

            // Create a new preview image from the processed image.
            const processedImg = await loadImage(processedBase64);
            const newPreview = cropImageToSquare(processedImg, sizes.preview);
            setPreviewImage(newPreview);

            // Raise onImageChanged with the new images.
            onImageChanged([processedBase64, newPreview]);
        } catch (error) {
            console.error("Error removing background:", error);
        } finally {
            setIsRemovingBackground(false);
        }
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex w-100 justify-content-center p-4">
                {previewImage ? (
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <img
                            style={{maxWidth: imageWidth, maxHeight: imageHeight}}
                            className="mt-2 rounded"
                            src={previewImage}
                            alt={imageAlt}
                        />
                    </OverlayTrigger>
                ) : (
                    // Display a placeholder if no preview is available.
                    <div>
                        {loadingOriginalImage && (
                            <div
                                style={{
                                    width: imageWidth,
                                    height: imageHeight,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#868e96"
                                }}
                            >
                                <Spinner animation="border" variant="primary" />
                            </div>
                        )}
                        {!loadingOriginalImage && (
                            <svg width={imageWidth} height={imageHeight}>
                                <rect width="100%" height="100%" fill="#868e96"/>
                                <text
                                    x="50%"
                                    y="50%"
                                    fill="#dee2e6"
                                    dy=".3em"
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                    fontSize={".5rem"}
                                >
                                    {imageWidth} x {imageHeight}
                                </text>
                            </svg>
                        )}
                    </div>
                )}
            </div>
            <div className="d-grid gap-2">
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <Button
                        variant={buttonVariant}
                        size="lg"
                        onClick={() => {
                            if (fileInputRef.current) fileInputRef.current.click();
                        }}
                    >
                        {buttonText}
                    </Button>
                </OverlayTrigger>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                accept="image/png, image/gif, image/jpeg"
                style={{display: "none"}}
                multiple={multiple}
                onChange={handleFileChange}
            />
            {/* Show the Remove Background button if an image is selected */}
            {previewImage && (
                <div className="d-grid gap-2 mt-2">
                    <LoadingButton variant="primary" onClick={handleRemoveBackground} isLoading={isRemovingBackground}>
                        {t("remove-background")}
                    </LoadingButton>
                </div>
            )}
        </div>
    );
};

// --- Utility Functions ---

// Calls the Photoroom API to remove the background from the given file.
async function removeBackground(file: File, apiKey: string): Promise<Blob> {

    const formData = new FormData();
    formData.append("image_file", file);
    
    // Replace with your actual Photoroom endpoint and API key.
    const response = await fetch("https://sdk.photoroom.com/v1/segment", {
        method: "POST",
        headers: {
            "X-API-Key": apiKey,
        },
        body: formData,
    });

    if (!response.ok) {
        if (response.status !== 200) {
            let errorMessage = "";
            if (response.status === 402)
                errorMessage =
                    "No tiene créditos suficientes para quitar el fondo de la imagen";
            else errorMessage = "Error al quitar el fondo de la imagen";
            console.log("Remove background response: ", response);
            alert(errorMessage);
            throw new Error("Failed to remove background");
        }
    }

    return await response.blob();
}

// Converts a Blob to a base64 string.
function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

// Loads an image from a base64 string.
function loadImage(base64: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = base64;
    });
}

// Crops the given image to a centered square and resizes it to the target size.
function cropImageToSquare(img: HTMLImageElement, targetSize: number): string {
    const canvas = document.createElement("canvas");
    canvas.width = targetSize;
    canvas.height = targetSize;
    const ctx = canvas.getContext("2d");
    if (!ctx) return "";

    // Determine the square crop area (centered).
    const cropSize = Math.min(img.width, img.height);
    const sx = (img.width - cropSize) / 2;
    const sy = (img.height - cropSize) / 2;

    // Draw the cropped image onto the canvas, scaling it to the target size.
    ctx.drawImage(img, sx, sy, cropSize, cropSize, 0, 0, targetSize, targetSize);

    return canvas.toDataURL("image/png");
}

// Converts a base64 string to a File.
function base64ToFile(base64: string, filename: string): File {
    const arr = base64.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch) {
        throw new Error("Invalid base64 string");
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}


// Converts an HTMLImageElement to a base64 string using a canvas.
function imageToBase64(img: HTMLImageElement): string {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) return img.src;
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
}


export default SelectImage;