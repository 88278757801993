import {Store} from "../../api/models/storeModel";
import {t} from "i18next";
import {FormCheck} from "react-bootstrap";

const StoreSettingsPaymentTypes = ({store, onPropertyChanged}: {
    store: Store,
    onPropertyChanged: (propertyName: string, value: any) => void
}) => {
    return (
        <div className="panel">
            <h6>{t("payment_title")}</h6>
            <FormCheck label={t("payment_credit_card")}
                       checked={store.stripePaymentEnabled}
                       onChange={(e) => onPropertyChanged("stripePaymentEnabled", e.currentTarget.checked)}/>
            <FormCheck label={t("payment_cash")}
                       checked={store.cashPaymentEnabled}
                       onChange={(e) => onPropertyChanged("cashPaymentEnabled", e.currentTarget.checked)}/>
        </div>
    )
}

export default StoreSettingsPaymentTypes;