import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {eProductTag, Product} from "../../api/models/productModel";
import {
    GetProductCategories,
} from "../../api/services/productCategoriesServices";
import {
    CreateProduct,
    DeleteProduct,
    GetProduct,
    GetProductTags,
    ProductTag,
    UpdateProduct as APIUpdateProduct,
    GetUnitsOfMeasure,
    GetSuppliers,
} from "../../api/services/productServices";
import SelectImage from "../../components/SelectImage";
import Tabs from "../../components/Tab";
import UpdateKeyword from "./UpdateKeyword";
import NumericInput from "../../components/NumericInput";
import {IError} from "../../api/models/errorModel";
import TitleBar from "../../components/TitleBar";
import ErrorModal from "../../components/ErrorModal";
import LoadingComponent from "../../components/LoadingComponent";
import {Typeahead} from "react-bootstrap-typeahead";
import {Option} from "react-bootstrap-typeahead/types/types";
import {eActionType} from "../../api/models/common";
import {t} from "i18next";
import {ProductCategory} from "../../api/models/productCategoryModel";

const UpdateProduct = () => {
    const [product, setProduct] = useState<Product>({
        id: 0,
        name: "",
        order: 0,
        tag: eProductTag.None,
        thumbUrl: "",
        removeImage: false,
        price: 0,
        cost: 0,
        addTransactionCharge: true,
        unitOfMeasure: "",
        interval: 1,
        productCategoryId: 0,
        disabled: false,
        outOfStock: false,
        locationCode: "",
        featured: false,
        searchTermsArray: [],
        searchTerms: "",
    });

    const [imageBase64, setImageBase64] = useState<string | undefined>();

    const [actionType, setActionType] = useState<eActionType>();

    const [error, setError] = useState<IError>();

    const [loadingError, setLoadingError] = useState<React.ReactNode>();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState<string | undefined>();

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const [selectedUOM, setSelectedUOM] = useState<Array<Option>>([
        {label: product.unitOfMeasure},
    ]);
    const [unitsOfMeasure, setUnitsOfMeasure] = useState<string[]>(
        GetUnitsOfMeasure()
    );

    const [suppliers, setSuppliers] = useState<string[]>(GetSuppliers());
    const [selectedSupplier, setSelectedSupplier] = useState<Array<Option>>([
        {label: product.supplierName ? product.supplierName : ""},
    ]);

    const [categories, setCategories] = useState<ProductCategory[]>([]);

    const [tags, setTags] = useState<ProductTag[]>(GetProductTags());

    const [editKeywordAction, setEditKeywordAction] = useState<eActionType>(
        eActionType.None
    );
    const [editingKeywordIndex, setEditingKeywordIndex] = useState<number>(-1);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            setIsLoading(true);
            setLoadingError(undefined);
            setError(undefined);

            const categories = await GetProductCategories();
            setCategories(categories);

            // Creating a new product
            if (!params.productId) {
                setActionType(eActionType.Create);

                // If there is just one category, set it as the default
                if (categories.length === 1) {
                    setProduct({
                        ...product,
                        productCategoryId: categories[0].id
                    })
                }
                return;
            }

            const productId = parseInt(params.productId);
            const productToEdit = await GetProduct(productId);
                        
            if (productToEdit) setProduct(productToEdit);
            if (!productToEdit) console.log("Error product not found", productId);

            setSelectedUOM([{label: productToEdit?.unitOfMeasure}]);
            setSelectedSupplier([
                {label: productToEdit?.supplierName ? productToEdit.supplierName : ""},
            ]);

            setActionType(eActionType.Update);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")}
                    <br/> {t("please_try_again")}.
                </p>
            );
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const save = async () => {
        if (!validate()) return;

        try {
            setIsLoading(true);
            setLoadingText(`${t("saving")}...`);
            setError(undefined);
            
            product.thumbBase64 = imageBase64;
            
            if (actionType === eActionType.Create) await CreateProduct(product);
            if (actionType === eActionType.Update)
                await APIUpdateProduct(product);

            navigate(-1);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({message: message as string});
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
    };

    const validate = () => {
        if (!product.name) {
            setError({
                message: t("updateProduct_name_required"),
                property: "name",
            });
            return false;
        }

        if (product.productCategoryId <= 0) {
            setError({
                message: t("updateProduct_category_required"),
                property: "category",
            });
            return false;
        }

        if (product.price <= 0) {
            setError({
                message: t("updateProduct_price_required"),
                property: "price",
            });
            return false;
        }

        /*  if (product.cost <= 0) {
            setError({
                message: t("updateProduct_cost_required"),
                property: "cost",
            });
            return false;
        }
*/
        if (product.price < product.cost) {
            setError({
                message: t("updateProduct_price_lower_than_cost"),
                property: "price",
            });
            return false;
        }

        if (!product.unitOfMeasure) {
            setError({
                message: t("updateProduct_unit_of_measurement_required"),
                property: "unitOfMeasure",
            });
            return false;
        }

        if (product.interval <= 0) {
            setError({
                message: t("updateProduct_interval"),
                property: "interval",
            });
            return false;
        }

        if (product.regularPrice && product.regularPrice < product.price) {
            setError({
                message: t("updateProduct_regular_price_lower_than_price"),
                property: "price",
            });
            return false;
        }

        if (
            actionType === eActionType.Update &&
            (!product.order || product.order < 0)
        ) {
            setError({
                message: t("updateProduct_order_required"),
                property: "order",
            });
            return false;
        }

        setError(undefined);
        return true;
    };

    const handleDeleteProduct = async () => {
        if (!window.confirm(t("confirm_delete"))) return;

        try {
            setLoadingText(`${t("deleting")}...`);
            setIsLoading(true);
            setError(undefined);
            await DeleteProduct(product);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({message: message as string});
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
        navigate("../products");
    };
    const handleAddKeyword = (keyword: string) => {
        if (!product.searchTermsArray) product.searchTermsArray = [];
        product.searchTermsArray.push(keyword);
        setProduct({...product});
        setEditKeywordAction(eActionType.None);
    };

    const handleUpdateKeyword = (keyword: string, index: number) => {
        if (!product.searchTerms) return;
        product.searchTermsArray[index] = keyword;
        setProduct({...product});
        setEditKeywordAction(eActionType.None);
    };

    const handleDeleteKeyword = (index: number) => {
        if (!product.searchTermsArray) return;
        product.searchTermsArray.splice(index, 1);
        setProduct({...product});
        setEditKeywordAction(eActionType.None);
    };

    const handleCalculatePrice = () => {
        if (!product.cost) return;

        if (product.margin) {
            let price = product.cost / (1 - product.margin / 100);
            price = parseFloat(price.toFixed(2));
            price = Math.ceil(price / 0.5) * 0.5;

            price = parseFloat(price.toFixed(2));

            setProduct({
                ...product,
                price: price,
            });
            return;
        }

        if (!product.price) return;

        let margin = ((product.price - product.cost) / product.price) * 100;
        setProduct({
            ...product,
            margin: parseFloat(margin.toFixed(2)),
        });
    };

    const precioFinal = () => {
        if (isNaN(product.price)) return 0;

        if (!product.addTransactionCharge) return product.price;

        let price = product.price * 1.02; //TODO: Obtener porcentaje de settings
        price = parseFloat(price.toFixed(2));

        return price;
    };

    const selectedCategorySubCategories = () => {
        if (!product.productCategoryId) return null;
        const category = categories.find(
            (c) => c.id === product.productCategoryId
        );
        if (!category) return null;

        if (category.subCategories && category.subCategories.length > 0)
            return category.subCategories;
    };

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={initialize}
                onOkClick={() => navigate(-1)}
            />
        );

    return (
        <div id="updateProduct">
            <TitleBar
                title={
                    actionType === eActionType.Create
                        ? t("updateProduct_title_add")
                        : t("updateProduct_title_edit")
                }
                backUrl="-1"
            />

            <Tabs
                tabs={[
                    t("updateProduct_tabs_general"),
                    t("updateProduct_tabs_extra"),
                    t("updateProduct_tabs_keywords"),
                ]}
                selectedTabIndex={selectedTab}
                onSelectedTabChanged={(s) => setSelectedTab(s)}
            />

            <Form className="panel">
                {selectedTab === 0 && (
                    <div id="generales">
                        <Form.Group id="name" className="formGroup">
                            <Form.Label className="required">
                                {t("name")}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t(
                                    "updateProduct_name_placeholder"
                                )}
                                value={product.name}
                                isInvalid={error?.property === "name"}
                                onChange={(e) =>
                                    setProduct({
                                        ...product,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group id="category" className="formGroup">
                            <Form.Label className="required">
                                {t("category")}
                            </Form.Label>
                            <Form.Select
                                value={product.productCategoryId}
                                isInvalid={error?.property === "category"}
                                onChange={(e) => {
                                    setProduct({
                                        ...product,
                                        productCategoryId: parseInt(
                                            e.currentTarget.value
                                        ),
                                    });
                                }}
                            >
                                <option>
                                    {t("updateProduct_select_category")}
                                </option>
                                {categories.map((c, i) => (
                                    <option key={i} value={c.id}>
                                        {c.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {selectedCategorySubCategories() && (
                            <Form.Group id="subCategory" className="formGroup">
                                <Form.Label className="required">
                                    Sub{t("category")}
                                </Form.Label>
                                <Form.Select
                                    value={product.productSubCategoryId}
                                    isInvalid={error?.property === "category"}
                                    onChange={(e) =>
                                        setProduct({
                                            ...product,
                                            productSubCategoryId: parseInt(
                                                e.currentTarget.value
                                            ),
                                        })
                                    }
                                >
                                    <option>
                                        {t("updateProduct_select_category")}
                                    </option>
                                    {selectedCategorySubCategories()!.map(
                                        (c, i) => (
                                            <option key={i} value={c.id}>
                                                {c.name}
                                            </option>
                                        )
                                    )}
                                </Form.Select>
                            </Form.Group>
                        )}
                        <Form.Group id="featured" className="formGroup">
                            <Form.Check
                                type="checkbox"
                                label={t("updateProduct_featured")}
                                checked={product.featured}
                                onChange={(e) =>
                                    setProduct({
                                        ...product,
                                        featured: !product.featured,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group id="image" className="formGroup">
                            <SelectImage
                                imageUrl={product.originalImageUrl ? product.originalImageUrl : ""}
                                imageWidth="128px"
                                imageHeight="128px"
                                imageAlt="La imagen del producto"
                                onImageChanged={(images) => {
                                    setImageBase64(images[0]);
                                    setProduct({
                                        ...product,
                                        thumbBase64: images[0],
                                    });
                                }}
                            />
                            {product.thumbBase64 && (
                                <button
                                    className="btn btn-lg btn-outline-danger w-100 mt-2"
                                    onClick={() => {
                                        setImageBase64(undefined);
                                        setProduct({...product, thumbUrl: "", thumbBase64: undefined, originalImageUrl:"/img/no-image.png"});
                                    }}>
                                    {t("storeSettingsCarousel_delete_image")}
                                </button>)}
                        </Form.Group>

                        <Form.Group id="cost" className="formGroup">
                            <Form.Label>
                                {t("updateProduct_cost")}
                            </Form.Label>
                            <NumericInput
                                min="0"
                                placeholder={t(
                                    "updateProduct_cost_placeholder"
                                )}
                                value={product.cost ? product.cost : ""}
                                isInvalid={error?.property === "cost"}
                                onValueChanged={(e) =>
                                    setProduct({
                                        ...product,
                                        cost: e,
                                    })
                                }
                            />
                        </Form.Group>

                        <div className="d-flex align-items-center ustify-content-between ">
                            <Form.Group
                                id="margin"
                                className="formGroup flex-grow-1 mt-2"
                            >
                                <Form.Label>
                                    {t("updateProduct_margin")} (%)
                                </Form.Label>
                                <NumericInput
                                    min="0"
                                    placeholder={t(
                                        "updateProduct_margin_placeholder"
                                    )}
                                    value={product.margin ? product.margin : ""}
                                    onValueChanged={(e) =>
                                        setProduct({
                                            ...product,
                                            margin: e,
                                        })
                                    }
                                />
                            </Form.Group>
                            <Button
                                variant="outline-primary"
                                className="ms-2 align-self-end"
                                onClick={handleCalculatePrice}
                            >
                                {t("calculate")}
                            </Button>
                        </div>

                        <Form.Group id="price" className="formGroup">
                            <Form.Label className="required">
                                {t("precio")}
                            </Form.Label>
                            <NumericInput
                                min="0"
                                placeholder={t(
                                    "updateProduct_price_placeholder"
                                )}
                                value={product.price ? product.price : ""}
                                isInvalid={error?.property === "price"}
                                onValueChanged={(e) =>
                                    setProduct({
                                        ...product,
                                        price: e,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Check
                                type="checkbox"
                                label={t("updateProduct_add_transaction_fee")}
                                checked={product.addTransactionCharge}
                                onChange={(e) =>
                                    setProduct({
                                        ...product,
                                        addTransactionCharge:
                                            !product.addTransactionCharge,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                {t("updateProduct_final_price")}:
                            </Form.Label>
                            <Form.Label>
                                {" "}
                                <strong>{precioFinal()}</strong>{" "}
                            </Form.Label>
                        </Form.Group>

                        <Form.Group id="unitOfMeasure" className="formGroup">
                            <Form.Label className="required">
                                {t("updateProduct_unit_of_measurement")}
                            </Form.Label>
                            <Typeahead
                                id="selectUOM"
                                selected={selectedUOM}
                                onChange={(selected) => {
                                    if (!selected) return;
                                    let uom = "";
                                    if (typeof selected[0] === "object") {
                                        uom = selected[0].label;
                                    } else {
                                        uom = selected[0];
                                    }
                                    if (!uom) {
                                        setSelectedUOM([]);
                                        return;
                                    }
                                    setProduct({
                                        ...product,
                                        unitOfMeasure: uom.toUpperCase(),
                                    });
                                    setSelectedUOM([
                                        {label: uom.toUpperCase()},
                                    ]);
                                }}
                                placeholder={t(
                                    "updateProduct_unit_of_measurement"
                                )}
                                allowNew
                                options={unitsOfMeasure}
                            />
                        </Form.Group>
                    </div>
                )}

                {
                    selectedTab === 1 && (
                        <div id="adicionales">
                            <Form.Group id="regularPrice" className="formGroup">
                                <Form.Label>
                                    {t("updateProduct_regular_price")}
                                </Form.Label>
                                <NumericInput
                                    min="0"
                                    placeholder=""
                                    value={
                                        product.regularPrice
                                            ? product.regularPrice
                                            : ""
                                    }
                                    onValueChanged={(e) =>
                                        setProduct({
                                            ...product,
                                            regularPrice: e,
                                        })
                                    }
                                />
                            </Form.Group>

                            <Form.Group id="interval" className="formGroup">
                                <Form.Label>
                                    {t("updateProduct_multiple")}
                                </Form.Label>
                                <NumericInput
                                    min="0"
                                    placeholder={t(
                                        "updateProduct_multiple_placeholder"
                                    )}
                                    value={product.interval ? product.interval : ""}
                                    isInvalid={error?.property === "interval"}
                                    onValueChanged={(e) =>
                                        setProduct({
                                            ...product,
                                            interval: e,
                                        })
                                    }
                                />
                            </Form.Group>

                            <Form.Group id="tag" className="formGroup">
                                <Form.Label>{t("updateProduct_tag")}</Form.Label>
                                <Form.Select
                                    value={product.tag}
                                    onChange={(e) =>
                                        setProduct({
                                            ...product,
                                            tag: parseInt(e.currentTarget.value),
                                        })
                                    }
                                >
                                    {tags.map((c, i) => (
                                        <option key={i} value={c.id}>
                                            {c.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            {actionType === eActionType.Update && (
                                <div>
                                    <Form.Group id="order" className="formGroup">
                                        <Form.Label>{t("orden")}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            placeholder={t(
                                                "updateProductCategory_order_placeholder"
                                            )}
                                            value={product.order}
                                            isInvalid={error?.property === "order"}
                                            onChange={(e) =>
                                                setProduct({
                                                    ...product,
                                                    order: parseInt(
                                                        e.currentTarget.value
                                                    ),
                                                })
                                            }
                                        />
                                    </Form.Group>
                                </div>
                            )}

                            <Form.Group id="locationCode" className="formGroup">
                                <Form.Label>
                                    {t("updateProduct_location")}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(
                                        "updateProduct_location_placeholder"
                                    )}
                                    value={product.locationCode}
                                    onChange={(e) =>
                                        setProduct({
                                            ...product,
                                            locationCode: e.currentTarget.value,
                                        })
                                    }
                                />
                            </Form.Group>

                            <Form.Group id="productStatus" className="formGroup">
                                <Form.Check
                                    type="checkbox"
                                    label={t("updateProduct_disabled")}
                                    checked={product.disabled}
                                    onChange={(e) =>
                                        setProduct({
                                            ...product,
                                            disabled: !product.disabled,
                                        })
                                    }
                                />
                            </Form.Group>
                            <Form.Group id="outOfStock">
                                <Form.Check
                                    type="checkbox"
                                    label={t("updateProduct_out_of_stock")}
                                    checked={product.outOfStock}
                                    onChange={(e) =>
                                        setProduct({
                                            ...product,
                                            outOfStock: !product.outOfStock,
                                        })
                                    }
                                />
                            </Form.Group>

                            <Form.Group id="supplier" className="formGroup">
                                <Form.Label>
                                    {t("updateProduct_supplier")}
                                </Form.Label>
                                <Typeahead
                                    id="selectSupplier"
                                    selected={selectedSupplier}
                                    onChange={(selected) => {
                                        if (!selected) return;
                                        let supplier = "";
                                        if (typeof selected[0] === "object") {
                                            supplier = selected[0].label;
                                        } else {
                                            supplier = selected[0];
                                        }
                                        if (!supplier) {
                                            setSelectedSupplier([]);
                                            return;
                                        }
                                        setProduct({
                                            ...product,
                                            supplierName: supplier.toUpperCase(),
                                        });
                                        setSelectedSupplier([
                                            {label: supplier.toUpperCase()},
                                        ]);
                                    }}
                                    placeholder=""
                                    allowNew
                                    options={suppliers}
                                />
                            </Form.Group>
                        </div>
                    )
                }

                {
                    selectedTab === 2 && (
                        <div>
                            <div id="toolbar" className="toolbar">
                                <div className="d-flex align-items-center flex-grow-1">
                                    {t("updateProduct_tabs_keywords")}
                                </div>
                                <Button
                                    id="addKeyword"
                                    variant="success"
                                    onClick={() =>
                                        setEditKeywordAction(eActionType.Create)
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus}/>
                                </Button>
                            </div>

                            <div className="list">
                                {product.searchTermsArray?.map((t, i) => (
                                    <div
                                        key={i}
                                        className="list-item"
                                        onClick={() => {
                                            setEditKeywordAction(
                                                eActionType.Update
                                            );
                                            setEditingKeywordIndex(i);
                                        }}
                                    >
                                        {t}
                                    </div>
                                ))}
                            </div>

                            {editKeywordAction === eActionType.Create && (
                                <UpdateKeyword
                                    actionType={editKeywordAction}
                                    keyword=""
                                    onHide={() =>
                                        setEditKeywordAction(eActionType.None)
                                    }
                                    onSave={handleAddKeyword}
                                />
                            )}

                            {editKeywordAction === eActionType.Update && (
                                <UpdateKeyword
                                    actionType={editKeywordAction}
                                    keyword={
                                        product.searchTermsArray
                                            ? product.searchTermsArray[
                                                editingKeywordIndex
                                                ]
                                            : ""
                                    }
                                    onHide={() =>
                                        setEditKeywordAction(eActionType.None)
                                    }
                                    onSave={(e) =>
                                        handleUpdateKeyword(e, editingKeywordIndex)
                                    }
                                    onDelete={() =>
                                        handleDeleteKeyword(editingKeywordIndex)
                                    }
                                />
                            )}
                        </div>
                    )
                }
            </Form>

            {
                error && (
                    <Alert className="mt-4 mb-2" variant="danger">
                        {error.message}
                    </Alert>
                )
            }

            <div className="bottom-toolbar">
                {actionType === eActionType.Update && (
                    <Button
                        size="lg"
                        variant="outline-danger"
                        onClick={handleDeleteProduct}
                    >
                        {t("eliminar")}
                    </Button>
                )}
                <Button size="lg" variant="success" onClick={save}>
                    {t("save")}
                </Button>
            </div>

            {
                isLoading && <LoadingComponent loadingText={loadingText}/>
            }
        </div>
    );
};

export default UpdateProduct;
