import {useEffect, useState} from "react";
import TitleBar from "../../components/TitleBar";
import {useNavigate} from "react-router-dom";
import {GetUsers} from "../../api/services/UsersServices";
import {tenantId} from "../../api/services/tenantServices";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";
import SearchEntry from "../../components/SearchEntry";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useSession} from "../../api/context/SessionContext";
import {t} from "i18next";
import Tabs from "../../components/Tab";

const UsersPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    interface UserViewModel {
        id: number;
        email: string;
        name: string;
        lastLogin: string;
        disabled: boolean;
    }

    const [users, setUsers] = useState<UserViewModel[]>([]);
    const [filterEnabled, setFilterEnabled] = useState<boolean>(true);
    const [filteredData, setFilteredData] = useState<UserViewModel[]>([]);

    const [searchText, setSearchText] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("877ca91a-05e8-4b40-a144-a052fdcacd50")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
            return;
        }

        fetchData();
    }, []);
    useEffect(() => {
        setFilteredData(filterData());
    }, [users, filterEnabled, searchText]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingError(undefined);
            const result = await GetUsers(tenantId);
            setUsers(result);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")}. <br/> {t("please_try_again")}.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterData = () => {
        let ds = users;

        if(filterEnabled) {
            ds = ds.filter((x) => !x.disabled);
        } else {
            ds = ds.filter((x) => x.disabled);
        }
                
        if (!ds) return [];
        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter(
                (x) =>
                    x.name.toLocaleLowerCase().includes(searchTextLower) ||
                    x.email.toLocaleLowerCase().includes(searchTextLower)
            );
        }
        
        return ds;
    };

    if (isLoading) return <LoadingComponent/>;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => setLoadingError(undefined)}
            />
        );

    return (
        <div>
            <TitleBar title={t("usersPage_title")}></TitleBar>

            <SearchEntry onChange={(e) => setSearchText(e)}/>
            <div id="toolbar" className="toolbar">
                <Button
                    variant="success"
                    onClick={() => navigate("../users-add")}
                >
                    <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </div>

            <Tabs tabs={[t("usersPage_filter_enabled"), t("usersPage_filter_disabled")]}
                  selectedTabIndex={filterEnabled ? 0 : 1}
                  onSelectedTabChanged={(e) => {
                      console.log(e);
                      setFilterEnabled(e === 0);}}/>    

            <div id="list">
                {filteredData.map((user,j) => (
                    <div
                        key={j}
                        className={`panel ${user.disabled ? "bg-secondary" : ""}`}
                        onClick={() => navigate(`../users/${user.id}`)}
                    >
                        <div className="fw-bold">{user.name}</div>
                        <div>{user.email}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UsersPage;
